import React from 'react'
import { Accordion } from 'react-bootstrap'

function TabAccordion(props) {
  const { data } = props
  return (
    <div>
      <Accordion flush>
        {data?.map(obj => (
          <Accordion.Item eventKey={obj.helpId} key={obj.helpId}>
            <Accordion.Header>{obj.helpTitle}</Accordion.Header>
            <Accordion.Body>
              {obj.helpDescription}
            </Accordion.Body>
          </Accordion.Item>
        ))}


      </Accordion>
    </div>
  )
}

export default TabAccordion
