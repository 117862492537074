import { generateTokenApi } from "../api/auth.api"

export const generateTokenAction = (payload, handleError) => {
  return async dispatch => {
    try {
      const data = await generateTokenApi(payload, handleError)
      // console.log({
      //   'data token action': data
      // })
      dispatch({
        type: 'GENERATE_TOKEN',
        payload: { data }
      })
      return data
    } catch (error) {
      console.log('error', error)
    }
  }
}