import React, { useEffect, useState } from 'react'
import { Col, Container, Image, Nav, Navbar, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { get } from '../utils/request'

function Footer() {
  const { hash } = useLocation()
  const jwtToken = useSelector(state => state.auth.generateToken)
  const [state, setstate] = useState({
    sosmedList: [],
    addressList: [],
    loading: true,
    statusMessage: ''
  })

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      getData()
    }
    return () => isMounted === false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getData = () => {
    getSocialMediaList()
    getSAddressList()
  }
  const getSocialMediaList = () => {
    get(
      `/api/v1/merchant/social-media/fetch`,
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json'
        }
      },
      success => {
        const sosmedList = success.data.data.sort((a, b) => parseFloat(a.socialMediaSequence) - parseFloat(b.socialMediaSequence))

        setstate(prevState => {
          return {
            ...prevState,
            sosmedList: sosmedList,
            loading: false
          }
        })
      },
      error => {
        setstate(prevState => {
          return {
            ...prevState,
            loading: false
          }
        })
        console.log('error request', error)
      }
    )
  }

  const getSAddressList = () => {
    get(
      `/api/v1/merchant/address/fetch`,
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json'
        }
      },
      success => {
        const addressList = success.data.data.sort((a, b) => parseFloat(a.addressSequence) - parseFloat(b.addressSequence))

        setstate(prevState => {
          return {
            ...prevState,
            addressList: addressList,
            loading: false
          }
        })
      },
      error => {
        setstate(prevState => {
          return {
            ...prevState,
            loading: false
          }
        })
        console.log('error request', error)
      }
    )
  }

  return (
    <div className="container-fluid">
      <footer>
        <Row className="bg-green p-2">
        </Row>
        <Row className="p-5 bg-main">
          <Container className='text-footer'>
            <Row>
              <Col>
                <div className="text-center pb-5">
                  <Image src={process.env.PUBLIC_URL + "/assets/veganku-1.svg"} alt="" />
                </div>
                <Container className="p-3">
                  <Row>
                    <hr className="bg-white border border-white" />
                  </Row>
                  <Row className="px-3">
                    <Col xs={12} lg={6}>
                      {/* <h5 className="text-white px-2">About</h5> */}
                      <Row>
                        <Navbar bg="bg-main" expand="lg" variant="light" className='navbar-expand'>
                          <Navbar.Toggle aria-controls="basic-navbar-nav" />
                          <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                              <Nav.Link href={process.env.PUBLIC_URL + "/"} >
                                <span className='text-white'>Home</span></Nav.Link>
                              <Nav.Link href={process.env.PUBLIC_URL + "/#about"} style={{ fontWeight: hash === "#about" ? "bold" : "normal" }}><span className='text-white'>About us</span></Nav.Link>
                              <Nav.Link href={process.env.PUBLIC_URL + "/#bePartner"} style={{ fontWeight: hash === "#bePartner" ? "bold" : "normal" }}><span className='text-white'>Be our partner</span></Nav.Link>
                              <Nav.Link href={process.env.PUBLIC_URL + "/#contact"} style={{ fontWeight: hash === "#contact" ? "bold" : "normal" }}><span className='text-white'>Contact Us</span></Nav.Link>
                              <Nav.Link href={process.env.PUBLIC_URL + "/#help"} style={{ fontWeight: hash === "#help" ? "bold" : "normal" }}><span className='text-white'>Help</span></Nav.Link>
                            </Nav>
                          </Navbar.Collapse>
                        </Navbar>
                      </Row>

                      <h5 className="text-white pt-5 px-2">Follow us for more</h5>
                      <Row>
                        <Navbar bg="bg-main" expand="lg" variant="light" className='navbar-expand'>
                          <Navbar.Toggle aria-controls="basic-navbar-nav" />
                          <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                              {state.sosmedList?.map(obj=> (
                              <Nav.Link href={obj.socialMediaUrl} key={obj.socialMediaId}><span className='text-white'>{obj.socialMediaTitle}</span></Nav.Link>


                              ))}
                              {/* <Nav.Link href="#link"><span className='text-white'>Facebook</span></Nav.Link>
                              <Nav.Link href="#link"><span className='text-white'>LinkedIn</span></Nav.Link> */}
                            </Nav>
                          </Navbar.Collapse>
                        </Navbar>
                      </Row>
                    </Col>
                    <Col>
                    <Row>
                      <Col xs={12} md={4}></Col>
                    {state.addressList?.map(obj=>(
                      <Col xs={12} md={8}>
                      <div className='text-office'>
                        <h5 className="text-white">{obj.addressTitle}</h5>
                        <p className="text-white"><div className="text-white" dangerouslySetInnerHTML={{__html: obj.addressDetail}} /></p>
                        <p className="text-white" >{obj.addressNumberPhone}</p>
                        <p className="text-white"><a href={obj.addressUrl} style={{textDecoration: 'none', color: 'white'}} target='_blank' rel='noreferrer'>View Map</a></p>
                      </div>
                      
                    </Col>
                    ))}
                      </Row>
                      {/* </Row> */}
                    </Col>
                  </Row>
                </Container>

                <div className="row">
                  <p className="py-5 text-center text-white"><a href={process.env.PUBLIC_URL + "/termscondition"} style={{textDecoration: 'none', color: 'white'}}>Terms & Condition </a>- <a href={process.env.PUBLIC_URL + "/privacypolicy"} style={{textDecoration: 'none', color: 'white'}}>Privacy Policy</a></p>
                  <p className="text-center text-white">© Vegan-ku! 2024</p>
                </div>
              </Col>


            </Row>
          </Container>

        </Row>
      </footer>
    </div>
  )
}

export default Footer
