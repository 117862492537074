import React from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

const NavigasiBar = (props) => {
  const history = useHistory()
  const { hash } = useLocation()
  return (
    <div>
      <Navbar bg="white" expand="lg" fixed="top">
        <Container>
          <Navbar.Brand onClick={() => history.push('/')}>
            <img
              // src="../../assets/veganku-leaf.svg"
              src={process.env.PUBLIC_URL + "/assets/veganku-leaf.svg"}
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            /></Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav className="me-auto justify-content-end" style={{ width: "100%" }}>
              <Nav.Link href={process.env.PUBLIC_URL + "/"} >Home</Nav.Link>
              <Nav.Link href={process.env.PUBLIC_URL + "/#about"} style={{ fontWeight: hash === "#about" ? "bold" : "normal" }}>About us</Nav.Link>
              <Nav.Link href={process.env.PUBLIC_URL + "/#bePartner"} style={{ fontWeight: hash === "#bePartner" ? "bold" : "normal" }}>Be our partner</Nav.Link>
              <Nav.Link href={process.env.PUBLIC_URL + "/#contact"} style={{ fontWeight: hash === "#contact" ? "bold" : "normal" }}>Contact us</Nav.Link>
              <Nav.Link href={process.env.PUBLIC_URL + "/#help"} style={{ fontWeight: hash === "#help" ? "bold" : "normal" }}>Help</Nav.Link>
              <NavDropdown title="Other" id="basic-nav-dropdown" style={{ fontWeight: props.menuName === "faq" || props.menuName === "termscondition" || props.menuName === "privacypolicy" ? "bold" : "normal" }}>
                <NavDropdown.Item onClick={() => history.push('faq')} style={{ fontWeight: props.menuName === "faq" ? "bold" : "normal" }}>FAQ</NavDropdown.Item>
                <NavDropdown.Item onClick={() => history.push('termscondition')} style={{ fontWeight: props.menuName === "termscondition" ? "bold" : "normal" }}>Terms & Conditions</NavDropdown.Item>
                {/* <NavDropdown.Item onClick={() => history.push('termscondition')} style={{ fontWeight: props.menuName === "termscondition" ? "bold" : "normal" }}>Terms & Conditions</NavDropdown.Item> */}
                <NavDropdown.Item onClick={() => history.push('privacypolicy')} style={{ fontWeight: props.menuName === "privacypolicy" ? "bold" : "normal" }}>Privacy Policy</NavDropdown.Item>
                {/* <NavDropdown.Item onClick={() => history.push('privacypolicy')} style={{ fontWeight: props.menuName === "privacypolicy" ? "bold" : "normal" }}>Privacy Policy</NavDropdown.Item> */}
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default NavigasiBar
