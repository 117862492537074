import React, { useEffect } from 'react'
import { Accordion, Col, Container, Row } from 'react-bootstrap'
// import TabAccordion from '../components/TabAccordion'
// import data from '../components/dummyData/dummyFaq.json'
// import GetApp from '../components/GetApp'
// import Template from '../components/Template'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { get } from '../utils/request'
import NavigasiBar from '../components/NavigasiBar'
import Footer from '../components/Footer'

function Faq() {
  const jwtToken = useSelector(state => state.auth.generateToken)
  const [state, setstate] = useState({
    faqList: []
  })

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      getData()
    }
    return () => isMounted === false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getData = () => {
    get(
      `/api/v1/merchant/faq/fetch`,
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json'
        }
      },
      success => {
        const faqList = success.data.data.sort((a, b) => parseFloat(a.faqSequence) - parseFloat(b.faqSequence))

        setstate(prevState => {
          return {
            ...prevState,
            faqList: faqList,
            loading: false
          }
        })
      },
      error => {
        setstate(prevState => {
          return {
            ...prevState,
            loading: false
          }
        })
        console.log('error request', error)
      }
    )
  }
  return (

    <>
      <NavigasiBar menuName="faq" />

      <Container>
        <Row>
          <Col className='p-5'>
            <h2 class="text-center py-3">Frequently Asked Questions</h2>
            <p class="text-lg-center" style={{ fontSize: '30px', color: '#001330' }}>Find your issue</p>
            {/* <TabAccordion data={data} /> */}
            <div>
              <Accordion flush>
                {state.faqList?.map(obj => (
                  <Accordion.Item eventKey={obj.faqId} key={obj.faqId}>
                    <Accordion.Header>{obj.faqTitle}</Accordion.Header>
                    <Accordion.Body>
                      <div dangerouslySetInnerHTML={{ __html: obj.faqDescription }} />
                      {/* {obj.faqDescription} */}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}


              </Accordion>
            </div>
            {/* <GetApp /> */}
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}

export default Faq
