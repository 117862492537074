import Axios from "axios";

export const generateTokenApi = (payload, handleError) =>
  Axios.post(process.env.REACT_APP_BASE_URL + '/api/v1/auth/getToken', payload, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => res)
    // .catch(error => handleError(error.response))
    .catch(error => error.response)