import React from 'react'
// import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Footer from './Footer'
import NavigasiBar from './NavigasiBar'

const Template = ({ children, page }) => {
  // const [state, setstate] = useState(true)
  const location = useLocation();
  let showHeader = true;
  let showFooter = true;

  if (location.pathname === '/') {
    showHeader = false;
    showFooter = false;
  }

  let newPath = location.pathname


  if (newPath === '/faq' || newPath === '/termscondition' || newPath === '/privacypolicy') {
    showFooter = false
    showHeader = false;

  }


  return (
    <>
      <div className='body'>

        {showHeader && <NavigasiBar menuName={page} />}
        {children}
        {showFooter && <Footer />}
      </div>
    </>
  )
}

export default Template
