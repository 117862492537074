import axios from 'axios'

export const get = (url, options, success, handleError) => {
  axios
    .get(`${process.env.REACT_APP_BASE_URL}${url}`, options)
    .then(response => {
      success(response)
    })
    .catch(error => {
      // console.log('Axios: get failed', error.response)
      handleError(error.response)
    })
}

export const post = (url, options, data, success, handleError) => {
  axios
    .post(`${process.env.REACT_APP_BASE_URL}${url}`, data, options)
    .then(response => {
      success(response)
    })
    .catch(error => {
      // console.log('Axios: post failed', error.response)
      handleError(error.response)
    })
}

export const put = (url, options, data, success, handleError) => {
  axios
    .put(`${process.env.REACT_APP_BASE_URL}${url}`, data, options)
    .then(response => {
      success(response)
    })
    .catch(error => {
      // console.log('Axios: put failed', error.response)
      handleError(error.response)
    })
}

export const del = (url, options, success, handleError) => {
  axios
    .delete(`${process.env.REACT_APP_BASE_URL}${url}`, options)
    .then(response => {
      success(response)
    })
    .catch(error => {
      // console.log('Axios: delete failed', error.response)
      handleError(error.response)
    })
}
