import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import BannerSlide from '../components/BannerSlide'
import TabAccordion from '../components/TabAccordion'
// import GetApp from '../components/GetApp'
// import Template from '../components/Template'
import { useSelector } from 'react-redux'
import { get, post } from '../utils/request'
import NavigasiBar from '../components/NavigasiBar'
import Footer from '../components/Footer'


const Home = () => {
  const jwtToken = useSelector(state => state.auth.generateToken)
  const [state, setstate] = useState({
    aboutList: [],
    helpList: [],
    contactUs: [],
    bannerList: [],
    partnerList: [],
    contact: {
      contact_us_question_name: '',
      contact_us_question_email: '',
      contact_us_question_message: ''
    },
    loading: true,
    errorEmail: false,
    errorName: false,
    statusMessage: ''
  })

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      getData()
    }
    return () => isMounted === false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getData = () => {
    getBanner()
    getAbout()
    getHelp()
    getOurPartner()
    getContactUs()
  }

  const getBanner = () => {
    get(
      `/api/v1/Banners/fetch`,
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json'
        }
      },
      success => {
        // console.log(success.data.data.items)
        const { items } = success.data.data
        let bannerWeb = items.filter(obj => obj.forWeb === 1)
        // console.log(bannerWeb)
        setstate(prevState => {
          return {
            ...prevState,
            bannerList: bannerWeb,
            loading: false
          }
        })
      },
      error => {
        setstate(prevState => {
          return {
            ...prevState,
            loading: false
          }
        })
        console.log('error request', error)
      }
    )
  }

  const getAbout = () => {
    get(
      `/api/v1/merchant/about/fetch`,
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json'
        }
      },
      success => {
        // console.log(success.data.data)
        const aboutList = success.data.data.sort((a, b) => parseFloat(a.aboutSequence) - parseFloat(b.aboutSequence))

        setstate(prevState => {
          return {
            ...prevState,
            aboutList: aboutList,
            loading: false
          }
        })
      },
      error => {
        setstate(prevState => {
          return {
            ...prevState,
            loading: false
          }
        })
        console.log('error request', error)
      }
    )
  }

  const getHelp = () => {
    get(
      `/api/v1/merchant/help/fetch`,
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json'
        }
      },
      success => {
        // console.log(success.data.data)
        const helpList = success.data.data.sort((a, b) => parseFloat(a.helpSequence) - parseFloat(b.helpSequence))

        setstate(prevState => {
          return {
            ...prevState,
            helpList: helpList,
            loading: false
          }
        })
      },
      error => {
        setstate(prevState => {
          return {
            ...prevState,
            loading: false
          }
        })
        console.log('error request', error)
      }
    )
  }

  const getOurPartner = () => {
    get(
      `/api/v1/merchant/our-partner/fetch`,
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json'
        }
      },
      success => {
        // console.log(success.data.data)
        const partnerList = success.data.data.sort((a, b) => parseFloat(a.ourPartnerSequence) - parseFloat(b.ourPartnerSequence))

        setstate(prevState => {
          return {
            ...prevState,
            partnerList: partnerList,
            loading: false
          }
        })
      },
      error => {
        setstate(prevState => {
          return {
            ...prevState,
            loading: false
          }
        })
        console.log('error request', error)
      }
    )
  }

  const getContactUs = () => {
    get(
      `/api/v1/merchant/contact-us/fetch`,
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json'
        }
      },
      success => {
        // console.log(success.data.data)
        const contactUs = success.data.data.sort((a, b) => parseFloat(a.contactUsSequence) - parseFloat(b.contactUsSequence))

        setstate(prevState => {
          return {
            ...prevState,
            contactUs: contactUs,
            loading: false
          }
        })
      },
      error => {
        setstate(prevState => {
          return {
            ...prevState,
            loading: false
          }
        })
        console.log('error request', error)
      }
    )
  }


  const handleInputContact = (e) => {
    setstate(prevState => {
      return {
        ...prevState,
        contact: {
          ...prevState.contact,
          [e.target.name]: e.target.value
        }
      }
    })
    if(e.target.name === "contact_us_question_email") {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!regex.test(e.target.value)) {
        setstate(prevState => {
          return {
            ...prevState,
           errorEmail: true
          }
        })
      } else {
        setstate(prevState => {
          return {
            ...prevState,
           errorEmail: false
          }
        })
      }
    }
      
    

    

  }

  const handleSubmitContact = (e) => {
    if (state.contact.contact_us_question_name === ""){
      setstate(prevState => {
        return {
          ...prevState,
          errorName: true
        }
      })
    } else if (state.contact.contact_us_question_email === ""){
      setstate(prevState => {
        return {
          ...prevState,
          errorEmail: true
        }
      })
    }
    else {
      post(
        `/api/v1/merchant/contact-us/question`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
          }
        },
        state.contact,
        success => {
          setstate(prevState => {
            return {
              ...prevState,
              statusMessage: 'success',
              contact: {
                contact_us_question_name: '',
                contact_us_question_email: '',
                contact_us_question_message: ''
              },
              // loading: false
            }
          })
        },
        error => {
          setstate(prevState => {
            return {
              ...prevState,
              statusMessage: 'error',
              // loading: false
            }
          })
          console.log('error request', error)
        }
      )
    }
    

  }

  return (
    // state.loading ? null :
    // <Template >
    <>
      <NavigasiBar menuName="" />
      <BannerSlide id="home" data={state.bannerList} />
      <Container id="about">
        {state.aboutList?.map((list, index) => (
          list.aboutType === 'left' ?
            <Row className='py-5' key={list.aboutId}>
              <Col xs={12} md={6}>
                {/* <Image src="../../assets/vegetables.png" fluid /> */}
                <div className='bg-about' style={{
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  borderRadius: '20px',
                  backgroundSize: 'cover',
                  position: 'relative',
                  display: ' inline-block',
                  height: 0,
                  paddingBottom: '100%',
                  width: '100%',

                  // width: '100%',
                  // height: '300',
                  backgroundImage: `url(${list.aboutImage})`
                  // backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/foto.jpg"})`
                  // backgroundImage: `url('https://images.unsplash.com/photo-1498837167922-ddd27525d352?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80')`
                }}></div>
                {/* <Image src={list.aboutImage} fluid /> */}

              </Col>
              <Col className='px-5'>
                <div className="py-5"></div>
                <h2 className="font-weight-bold py-5">{list.aboutTitle}</h2>

                <Row>
                  {/* <Col xs={1}>
                <div className='circle-green'>{index + 1}</div>
              </Col> */}
                  <Col>
                    <div dangerouslySetInnerHTML={{ __html: list.aboutDescription }} />

                    {/* <p>{list.aboutDescription} </p> */}
                  </Col>
                </Row>


              </Col>
            </Row>
            :

            <Row key={list.aboutId}>
              <Col xs={12} md={6}>
                {/* <Image src="../../assets/vegetables.png" fluid /> */}
                <div className="py-5"></div>
                <h2 className="font-weight-bold color-green py-5">{list.aboutTitle}</h2>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. </p>
          <h4 className="font-weight-bold">Pellentesque tortor dictum in a nulla sem. </h4>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Scelerisque ac est nisl viverra porttitor massa.
            Et gravida consequat et tellus amet consectetur rutrum egestas. Hendrerit donec sit sed duis leo, quis
            venenatis placerat. Mauris vitae sem ipsum ipsum imperdiet adipiscing lobortis.Magna nam cras aliquet at
            nibh. Orci ac ut nulla.

            Gravida consequat et tellus amet consectetur rutrum egestas. Hendrerit donec sit sed duis leo, quis
            venenatis placerat. Mauris vitae sem ipsum ipsum

          </p> */}
                <div dangerouslySetInnerHTML={{ __html: list.aboutDescription }} />

                {/* <p>{list.aboutDescription} </p> */}
              </Col>
              {/* <Col>
          <Row>
            <Col>
              <Row>
                <Image src={process.env.PUBLIC_URL + "/assets/foods2.png"} width="100%" alt="" />
              </Row>
              <Image src={process.env.PUBLIC_URL + "/assets/vegetables-small.png"} width="100%" alt="" />
            </Col>
            <Col className="col-md-6">
              <Image src={process.env.PUBLIC_URL + "/assets/foods1.png"} width="100%" alt="" />
            </Col>
          </Row>


        </Col> */}
              <Col xs={12} md={6}>
                {/* <Image src="../../assets/vegetables.png" fluid /> */}
                <div className='bg-about' style={{
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  borderRadius: '20px',
                  position: 'relative',
                  display: ' inline-block',
                  height: 0,
                  paddingBottom: '100%',
                  width: '100%',

                  // width: '100%',
                  // height: '300',
                  backgroundImage: `url(${list.aboutImage})`
                  // backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/medium.jpg"})`

                }}></div>
                {/* <Image src={list.aboutImage} fluid /> */}

              </Col>
            </Row>

        )

        )}

        <Row>
          <Col className='py-5'>
            <h2 className="py-3">Many different ways we serve your everyday needs.</h2>
            <Row>
              <Col xs={6} md={3}>
                <div className="container-overlay">
                  <img src={process.env.PUBLIC_URL + "/assets/photo1.png"} alt="Avatar" className="image-overlay" />
                  <div className="overlay">
                    <div className="text-overlay">Food</div>
                  </div>
                </div>
                {/* <img src="../../assets/photo1.png" alt="" /> */}
              </Col>
              <Col xs={6} md={3}>
                <div className="container-overlay">
                  <img src={process.env.PUBLIC_URL + "/assets/photo2.png"} alt="Avatar" className="image-overlay" />
                  <div className="overlay">
                    <div className="text-overlay">Delivery</div>
                  </div>
                </div>
              </Col>
              <Col xs={6} md={3}>
                <div className="container-overlay">
                  <img src={process.env.PUBLIC_URL + "/assets/photo3.png"} alt="Avatar" className="image-overlay" />
                  <div className="overlay">
                    <div className="text-overlay">Community</div>
                  </div>
                </div>
              </Col>
              <Col xs={6} md={3}>
                <div className="container-overlay">
                  <img src={process.env.PUBLIC_URL + "/assets/photo4.png"} alt="Avatar" className="image-overlay" />
                  <div className="overlay">
                    <div className="text-overlay">Article</div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container id="bePartner">
        <Row>
          <Col className='pb-5'>
            <h1 className="pt-3">We have strong partnership.</h1>
            <h3 className="pb-5">Do you want to be our partnership?</h3>
            <Row>
              <Col xs={12} lg={4}>
                <div className="quarter">
                  <div className="p-4 pQuarter"></div>
                  <h1 className="d-flex align-items-center p-5 text-white">We've integreted with the below partner and
                    more</h1>
                </div>
              </Col>
              <Col xs={12} lg={8}>
                <Row>
                  {/* <Col xs={6} lg={3}> */}
                  {state.partnerList?.map((obj, index) => (
                    index < 3 ?
                      <Col xs={6} lg={4} style={{ margin: 'auto', paddingTop: '20px', textAlign: 'center' }}>

                        <img src={obj.ourPartnerImage} alt="" width="150" />
                      </Col> :
                      index > 2 && index < 5 ?
                        //  <Col xs={6} style={{background: 'black', margin: 'auto', paddingTop: '40px', textAlign: index === 3 ? 'end': 'start'}}>
                        <Col xs={6} style={{ margin: 'auto', paddingTop: '40px', paddingLeft: index === 3 ? '0px' : '40px', paddingRight: index === 3 ? '40px' : '0', textAlign: index === 3 ? 'end' : 'start' }}>

                          <img src={obj.ourPartnerImage} alt="" width="150" align="center" />
                        </Col> : null

                  ))}
                </Row>


                {/* <img src={process.env.PUBLIC_URL + "/assets/mcd.png"} alt="" width="250" />
                <img src={process.env.PUBLIC_URL + "/assets/burgerking.png"} alt="" width="130" />
                <img src={process.env.PUBLIC_URL + "/assets/kfc.png"} alt="" width="200" />

                <img src={process.env.PUBLIC_URL + "/assets/grab.png"} alt="" height="70" className="px-5" />
                <img src={process.env.PUBLIC_URL + "/assets/gojek.png"} alt="" height="100" className="px-5" /> */}
              </Col>
              <div className='footer-partner'><p>Many more</p></div>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container fluid className='bg-light py-5' id="contact">
        <Row >
          <Col>
            <Container>
              <Row className='pb-5'>
                <Col><h4 className="text-center color-green">Got a question?</h4>
                  <h1 className="text-center">Contact Vegan-ku</h1>
                  <p className="text-center">We're here to help and answer any question you might have. We look forward to
                    hearing from you!</p></Col>
              </Row>
              <Row direction="horizontal" gap={3}>
                <div className="col-md-6">
                  {/* <Form>  */}
                  <Form.Group className="mb-4" controlId="formGroupName">
                    <Form.Label><strong>Name (Required)</strong> </Form.Label>
                    <Form.Control type="text" placeholder="Insert Your Name" onChange={(e) => handleInputContact(e)} name='contact_us_question_name' value={state.contact.contact_us_question_name} />
                    {state.errorName  ? <p style={{ color: '#FF0000' }}>Please enter your name</p> : ''}
                  </Form.Group>
                  <Form.Group className="mb-4" controlId="formGroupEmail">
                    <Form.Label><strong>Email Address (Required)</strong></Form.Label>
                    <Form.Control type="email" placeholder="ex: johndoe@mail.com" onChange={(e) => handleInputContact(e)} name='contact_us_question_email' value={state.contact.contact_us_question_email} />
                    {state.errorEmail  ? <p style={{ color: '#FF0000' }}>Please enter valid email</p> : ''}
                  </Form.Group>
                  <Form.Group className="mb-4" controlId="formGroupEmail">
                    <Form.Label for="exampleFormControlTextarea1" ><strong>Message (Required)</strong> </Form.Label>
                    <Form.Control as="textarea" rows="3" placeholder="Insert message here .." onChange={(e) => handleInputContact(e)} name='contact_us_question_message' value={state.contact.contact_us_question_message}></Form.Control>
                    
                  </Form.Group>
                  {state.statusMessage === 'success' ? <p style={{ color: '#00B83F' }}>Successfully sent message</p> :
                    state.statusMessage === 'error' ? <p style={{ color: '#FF0000' }}>Failed to send message</p> : ''
                  }
                  {/* <button className="w-32 py-2 text-sm text-white bg-green-500 rounded-full focus:outline-none hover:shadow-md" onClick={() => handleSubmitContact()}>Submit</button> */}

                  <Button style={{ backgroundColor: '#00B83F' }} as="input" type="submit" value="Submit" onClick={() => handleSubmitContact()} />
                  {/* </Form> */}
                </div>
                {state.contactUs?.map((list, index) => (
                  list.contactUsId === "d094f418-894d-11ec-89fd-ce3db9ef81cb" ?
                    <div className="col-md-6 py-4">
                      <h2>{list.contactUsTitle}</h2>
                      <div dangerouslySetInnerHTML={{ __html: list.contactUsDescription }} />
                      {/* <p>Our support team will be in touch very soon. We try to respond to all request within 24 hours
                        (during weekdays) </p>
                      <p>Support hours are Monday through Friday <br /><strong>9am-5pm WIB +7.</strong></p>
                      <p></p>
                      <p>Want to get a head start on finding solution? <br />
                        Be sure to check out our <span className="color-green font-weight-boold">troubleshooting</span> and our <span className="color-green font-weight bold">Documentation</span></p> */}
                    </div> :
                    ''
                ))}

              </Row>
            </Container>
          </Col>

        </Row>
      </Container>
      <Container id="help">
        <Row>
          <Col className='p-5'>
            <h2 className="text-center py-3">Help by category</h2>
            <p className="text-lg-center">We’re here to help and answer any question you might
              have. We look forward to hearing from you!</p>
            <TabAccordion data={state.helpList} />
            {/* <GetApp /> */}
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
    // </Template >
  )
}

export default Home
