import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Footer from '../components/Footer'
// import GetApp from '../components/GetApp'
import NavigasiBar from '../components/NavigasiBar'
// import Template from '../components/Template'
import { get } from '../utils/request'

function PrivacyPolicy() {
  const jwtToken = useSelector(state => state.auth.generateToken)
  const [state, setstate] = useState({
    privacyList: []
  })

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      getData()
    }
    return () => isMounted === false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getData = () => {
    get(
      `/api/v1/website/privacy-policy/fetch`,
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json'
        }
      },
      success => {
        const privacyList = success.data.data.sort((a, b) => parseFloat(a.privacyPoliciesSequence) - parseFloat(b.privacyPoliciesSequence))

        setstate(prevState => {
          return {
            ...prevState,
            privacyList: privacyList,
            loading: false
          }
        })
      },
      error => {
        setstate(prevState => {
          return {
            ...prevState,
            loading: false
          }
        })
        console.log('error request', error)
      }
    )
  }

  return (
    <>
      <NavigasiBar menuName="privacypolicy" />

      <Container>
        <Row>
          <Col className='p-5'>
            <h2 class="text-center py-3" style={{ fontSize: '30px' }}>Privacy Policy</h2>
            {/* <TabAccordion data={data}/> */}
          </Col>
        </Row>

        {state.privacyList?.map((obj, index) => (
          index === 0 ?
            <Row className='pb-4'>
              <Col xs={12} lg={6}>
                {/* <Image src={obj.privacyPoliciesImage} fluid /> */}
                <div className='bg-about' style={{
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  borderRadius: '20px',
                  backgroundSize: 'cover',
                  position: 'relative',
                  display: ' inline-block',
                  height: 0,
                  paddingBottom: '100%',
                  width: '100%',

                  // width: '100%',
                  // height: '300',
                  backgroundImage: `url(${obj.privacyPoliciesImage})`
                  // backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/foto.jpg"})`
                  // backgroundImage: `url('https://images.unsplash.com/photo-1498837167922-ddd27525d352?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80')`
                }}></div>

              </Col>
              <Col>
                <h3 class="">{obj.privacyPoliciesTitle}</h3>
                <p>{obj.privacyPoliciesDescription}</p>

              </Col>
            </Row> :
            <Row key={obj.privacyPoliciesId}>
              <Col className='py-1 ml-1'>
                <h6 className='pb-1'>{obj.privacyPoliciesTitle}</h6>
                {/* <h6>Pellentesque tortor dictum in a nulla sem. </h6> */}
                <p> {obj.privacyPoliciesDescription}
                </p>
              </Col>
            </Row>
        ))}
        {/* <GetApp /> */}


      </Container>
      <Footer />
    </>
  )
}

export default PrivacyPolicy
