import React, { useEffect, useState } from 'react'
import { Accordion, Col, Container, Image, Row } from 'react-bootstrap'
// import GetApp from '../components/GetApp'
// import Template from '../components/Template'
import { useSelector } from 'react-redux'
import { get } from '../utils/request'
import NavigasiBar from '../components/NavigasiBar'
import Footer from '../components/Footer'

function TermsCondition() {
  const jwtToken = useSelector(state => state.auth.generateToken)
  const [state, setstate] = useState({
    termsList: []
  })

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      getData()
    }
    return () => isMounted === false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getData = () => {
    get(
      `/api/v1/website/term-and-condition/fetch`,
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json'
        }
      },
      success => {
        const termsList = success.data.data.sort((a, b) => parseFloat(a.termAndConditionSequence) - parseFloat(b.termAndConditionSequence))

        setstate(prevState => {
          return {
            ...prevState,
            termsList: termsList,
            loading: false
          }
        })
      },
      error => {
        setstate(prevState => {
          return {
            ...prevState,
            loading: false
          }
        })
        console.log('error request', error)
      }
    )
  }
  return (
    <>
      <NavigasiBar menuName="termscondition" />

      <Container>
        <Row>
          <Col className='p-5'>
            <h2 class="text-center py-3" style={{ fontSize: '30px' }}>Terms and conditions</h2>

            <Image src={process.env.PUBLIC_URL + "/assets/image-hall.png"} fluid />
            <p className='pt-3' style={{ fontSize: '30px', color: '#001330' }}>From October 2021</p>
            {/* <TabAccordion data={data}/> */}
            <div>
              <Accordion flush>
                {state.termsList?.map(obj => (
                  <Accordion.Item eventKey={obj.termAndConditionId} key={obj.termAndConditionId}>
                    <Accordion.Header>{obj.termAndConditionTitle}</Accordion.Header>
                    <Accordion.Body>
                    {/* <div dangerouslySetInnerHTML={{__html: obj.termAndConditionDescription}} /> */}
                    <div style={{whiteSpace: "pre-wrap"}} >{obj.termAndConditionDescription}</div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}


              </Accordion>
            </div>
            {/* <GetApp /> */}
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}

export default TermsCondition
