import React, { Suspense } from 'react'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import Veganku from './pages';
import Faq from './pages/Faq';
import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsCondition from './pages/TermsCondition';


const VegankuApp = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Veganku>
        <Suspense>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route  path="/faq" component={Faq} />
            <Route  path="/privacypolicy" component={PrivacyPolicy} />
            <Route  path="/termscondition" component={TermsCondition} />
          </Switch>
        </Suspense>
      </Veganku>
    </Router>
  )
}

export default VegankuApp