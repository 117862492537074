import React from 'react'
// import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap'
// import { useHistory } from 'react-router-dom'

function BannerSlide(props) {
  // const [hover, setHover] = useState(false)
  // const history = useHistory()

  const list = props.data
  const listStatus = list.filter(obj => obj.status === 1)
  const banner = listStatus.sort((a, b) => parseFloat(a.sequence) - parseFloat(b.sequence))

  return (
    <div>
      <Carousel controls={false}>
        {banner.map(obj => (
          <Carousel.Item interval={1000}>
            <div className='container-banner'>
              <img
                className="img-banner"
                src={obj.image}
                alt="First slide"
              // style={{width:"100%", height: "40%"}}
              />
              <div className='banner'>
                <div className='text-banner'>
                  <h1>{obj.title}</h1>
                  <p>{obj.description}</p>
                </div>
                {/* <button className="btn-banner"
                  onMouseOver={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  // onClick={() => history.push('#about')}
                  style={{ fontWeight: hover ? "bold" : '' }}>View Details {hover ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                  </svg> : ''} </button> */}
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  )
}

export default BannerSlide
