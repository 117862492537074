import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { Suspense, useEffect, /*useState*/ } from 'react'
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
// } from 'react-router-dom'
// import Home from './pages/Home';
// import NotFound from './components/NotFound';
// import Faq from './pages/Faq';
import './style.css'
// import TermsCondition from './pages/TermsCondition';
// import PrivacyPolicy from './pages/PrivacyPolicy';
// import NotFound from './components/NotFound';
import { generateTokenAction } from './redux/action/auth.action';
import { useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import VegankuApp from './index.app';

// const Home = lazy(() => import('./pages/Home'))
// const Faq = lazy(() => import('./pages/Faq'))
// const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'))
// const TermsCondition = lazy(() => import('./pages/TermsCondition'))
// const NotFound = lazy(() => import('./components/NotFound'))

function App() {
  const dispatch = useDispatch()
  // const masterMenus = useSelector(state => state.memberPriviledgeMenu.priviledges)
  // const user = useSelector(state => state.auth.user)
  // const [state, setState] = useState({})

  useEffect(() => {
    // dispatch(
    //   generateTokenAction({
    //     clientKey: process.env.REACT_APP_CLIENT_KEY,
    //     secretKey: process.env.REACT_APP_SECRET_KEY
    //   }, (error) => setState(error)))

    dispatch(
      generateTokenAction({
        clientKey: "KbKkbfsUvoBjIeRVOkYBdqkvWqjAxybT",
        secretKey: "QiywTNrgLqnhkGBFgrGQjxPdYUjGjhYR"
      })
    )
      .then(res => {
        // console.log(res)
        // if (res.status !== 200) {
        //   console.log(res.config.url + ' - ' + res.statusText)
        // }
      })
      .catch(err => console.log('error get token', err))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    // <Router basename={process.env.PUBLIC_URL}>
    // <Routes>
    //   <Route exact path='/' element={<Home/>}/>
    //   {/* <Route exact path='/home' element={<Home/>}/> */}
    //   {/* <Route exact path='/about' /> */}
    //   <Route exact path='/faq' element={<Faq />}/>
    //   <Route exact path='/privacypolicy' element={<PrivacyPolicy />} />
    //   <Route exact path='/termscondition'element={<TermsCondition />} />
    //   {/* <Route exact path='/' /> */}
    //   {/* <Route path="*" element={<NotFound />}/> */}
    // </Routes>
    // </Router>

    <BrowserRouter>
    <Suspense fallback={""}>
      <VegankuApp />
    </Suspense>
    
    </BrowserRouter>
  );
}

export default App;
