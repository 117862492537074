const initialState = {
  generateToken: null
}

export const AuthReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case 'GENERATE_TOKEN': {
      return {
        ...state,
        generateToken: payload.data.status === 200 ? payload.data.data.data.token : null
      }
    }

    default: return state
  }
}