import React from 'react'
import Template from '../components/Template'

const Veganku = (props) => {
  return (
    <Template>
      {props.children}
    </Template>
  )
}

export default Veganku